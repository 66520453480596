import React from 'react';
import {getRoutes} from "../../components/shared/routes";
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import css from "./forest.module.scss";
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import BackToTraceabilityButton from "../../components/shared/ButtonBackTo/BackToTraceabilityButton";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import Video1 from "./forest_plots_of_land_creation.mp4";
import Video2 from "./display_the_selected_areas_on_the_web_client.mp4";
import Video3 from "./deforestation_analysis.mp4";
import VideoWithPreloadedImage from "../../components/shared/VideoWithPreloadedImage/VideoWithPreloadedImage";
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid} from "../../components/componentsUtils";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_pages_forest"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 500, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const ForestPage = () => {
    const routes = getRoutes();
    const pageTitle = routes.TraceabilityForest.pageTitle;
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <Breadcrumbs
                title={routes.TraceabilityForest.pageTitle}
                parentTitles={[routes.Traceability.pageTitle]}
                parentRoutes={[routes.Traceability]}
            />

            <div className={css.wrapper}>
                <div className={css.title}>
                    {routes.TraceabilityForest.pageTitle}
                </div>
                <div className={css.imageSectionWrapper}>
                    <div className={css.imageWrapper}>
                        <VideoWithPreloadedImage
                            videoSrcURL={Video1}
                            placeholderImage={'forest_plots_of_land_creation.jpg'}
                            placeholderImageAlt={'forest plots of land creation'}
                            imagesFluid={imagesFluid}
                        />
                        <div className={css.imageText}>
                            Forest plots of land creation by setting the geo-location polygon data.
                        </div>
                    </div>
                    <div className={css.imageWrapper}>
                        <VideoWithPreloadedImage
                            videoSrcURL={Video2}
                            placeholderImage={'display_the_selected_areas_on_the_web_client.jpg'}
                            placeholderImageAlt={'display the selected areas on the web client'}
                            imagesFluid={imagesFluid}
                        />
                        <div className={css.imageText}>
                            Display the selected areas on the Web Client Interface screen and view available information
                            and data created by the timber log authentication and verification system functionality.
                        </div>
                    </div>
                    <div className={css.imageWrapper}>
                        <VideoWithPreloadedImage
                            videoSrcURL={Video3}
                            placeholderImage={'deforestation_analysis.jpg'}
                            placeholderImageAlt={'deforestation analysis'}
                            imagesFluid={imagesFluid}
                        />
                        <div className={css.imageText}>
                            Use satellite images preview in selected timeframes for deforestation analysis.
                        </div>
                    </div>
                </div>
                <BackToTraceabilityButton/>

                <AdditionalInformation/>

                <Footer/>

            </div>
        </Layout>
    );
};

export default ForestPage;
